import * as R from 'ramda';
import {RouteConfig} from '../types';
import {
  policySchemaVoter,
  AuthorizationDecision,
  UserProfile,
} from '@regulatory-platform/common-utils';
import {getDomain, isProduction} from 'utils/global';
import {BuiltInRoles} from '@regulatory-platform/common-utils/dist';

export default function userProfileAuthorisedForRoute(
  route: RouteConfig,
  userProfile?: UserProfile,
  options?: {ignoreImpersonationPolicies?: boolean},
): boolean {
  const currentDomain = getDomain();
  if (R.isNil(route.authorisation)) {
    return true;
  }
  if (
    !R.isNil(route.authorisation.domain) &&
    route.authorisation.domain !== currentDomain
  ) {
    return false;
  }
  if (
    route.authorisation.policies.length === 1 &&
    route.authorisation.policies[0] === BuiltInRoles.EVERYONE
  ) {
    return true;
  }
  if (R.isNil(userProfile)) {
    return false;
  }
  if (route.authorisation.denyInProduction === true && isProduction()) {
    return false;
  }
  if (
    !R.isNil(route.authorisation.apiKey) &&
    !R.isNil(userProfile?.featureTokens) &&
    userProfile.featureTokens.length > 0 &&
    R.includes(route.authorisation.apiKey, userProfile.featureTokens)
  ) {
    return false;
  }
  let processedPolicies = route.authorisation.policies.map(policy => {
    if (R.is(String, policy)) {
      return policy;
    }
    return R.dissoc('accountId', policy);
  });
  if (options?.ignoreImpersonationPolicies) {
    processedPolicies = processedPolicies.filter(policy => {
      if (R.is(String, policy) || R.isNil(policy.role)) {
        return true;
      }
      return policy.role !== '$impersonator';
    });
  }
  return (
    policySchemaVoter(userProfile)(processedPolicies) ===
    AuthorizationDecision.ALLOW
  );
}
