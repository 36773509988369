import HomeIcon from '@mui/icons-material/Home';
import {UserProfile} from '@regulatory-platform/common-utils';
import userProfileAuthorisedForRoute from 'app/router/utils/userProfileAuthorisedForRoute';
import {ItemProps, SectionPropTypes} from 'components/navigation/types';
import * as R from 'ramda';
import {
  authenticatedContainers,
  unAuthenticatedContainers,
} from '../containerRegistry';
import {ContainerConfig, RouteConfig} from '../types';
import {isProduction} from 'utils/global';

export default function getSideBarSections(
  userProfile?: UserProfile,
  pathname?: string,
  includeHiddenMenuItems?: boolean,
): SectionPropTypes[] {
  const isAuthenticatedUser: boolean = !!(userProfile && userProfile.id > 0);
  const containers: ContainerConfig[] = isAuthenticatedUser
    ? authenticatedContainers
    : unAuthenticatedContainers;

  return R.concat(
    [
      {
        id: 'dashboard',
        hasChildren: false,
        items: [
          {
            id: '/',
            menuTitle: 'Home',
            icon: HomeIcon,
            selected: '/' === pathname,
            navigateTo: '',
          },
        ],
      } as SectionPropTypes,
    ],
    containers
      .filter(container => {
        const hasVisibleChildren = container.routes.some(
          route =>
            route.visibleInMenu &&
            userProfileAuthorisedForRoute(route, userProfile) &&
            !(route.hideInProduction && isProduction()),
        );

        return !!container.showChildRoutesInMenu && hasVisibleChildren;
      })
      .map(container => ({
        id: container.path,
        menuTitle: container.menuTitle,
        menuDescription: container.menuDescription,
        hasChildren: container.showChildRoutesInMenu,
        icon: container.menuIcon,
        items: R.reduce(
          (accMenuItems: ItemProps[], route: RouteConfig) => {
            if (
              (includeHiddenMenuItems || route.visibleInMenu) &&
              userProfileAuthorisedForRoute(route, userProfile, {
                ignoreImpersonationPolicies: true,
              })
            ) {
              accMenuItems.push({
                id: container.path + route.path,
                menuTitle: route.menuTitle,
                navigateTo: container.path + route.path,
                icon: route.menuIcon,
                selected:
                  '/' === pathname
                    ? false
                    : !R.isNil(pathname) &&
                      pathname.indexOf(container.path + route.path) === 0,
              });
            }
            return accMenuItems;
          },
          [],
          container.routes,
        ),
      })),
  );
}
