import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';
import {useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useUserProfile} from 'app/services/useUserProfile';

export const useAppBar = (map?: boolean) => {
  const userProfileIncTestFake = useUserProfile();
  const userProfile =
    userProfileIncTestFake.id === 0 ? undefined : userProfileIncTestFake;
  const accountType =
    userProfile?.accountType ?? ('' as AuthorisationAccountTypes);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isProfileMenuOpen = Boolean(anchorEl);
  const anchorRef = useRef(null);

  const navigate = useNavigate();

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    const url = new URL(window.location.href);
    if (url.pathname === '/login') {
      return;
    }
    url.searchParams.delete('returnUrl');
    const params = url.searchParams.toString();
    const returnUrl = `${url.pathname}${params ? `?${params}` : ''}`;

    const newUrlSearchParams = new URLSearchParams();
    newUrlSearchParams.set('returnUrl', returnUrl);
    navigate(`/login?${newUrlSearchParams.toString()}`);
  };

  const logoutUrl = useMemo(() => {
    if (!map) {
      return '/logout';
    }

    const url = new URL(window.location.href);
    const params = url.searchParams.toString();
    const returnUrl = `${url.pathname}${params ? `?${params}` : ''}`;

    const newUrlSearchParams = new URLSearchParams();
    newUrlSearchParams.set('returnUrl', returnUrl);
    return `/logout?${newUrlSearchParams.toString()}`;
  }, [map, window.location.href]);

  const toggleSidebarOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigateToHome = (): void => {
    navigate('/');
  };

  const navigateToUnAuthenticatedHome = (): void => {
    navigate('/information');
  };

  return {
    anchorEl,
    anchorRef,
    userProfile,
    accountType,
    isProfileMenuOpen,
    isSidebarOpen,
    toggleSidebarOpen,
    handleProfileMenuOpen,
    handleMenuClose,
    handleLogin,
    showNotifications: !!(
      userProfile &&
      userProfile?.accountType &&
      userProfile?.accountId &&
      logoutUrl
    ),
    navigateToHome,
    navigateToUnAuthenticatedHome,
  };
};
