import React from 'react';

import {Theme, useTheme} from '@mui/material';
import splash from 'assets/splash.jpg';
import {i18n} from 'containers-content';

import {
  Body1,
  Box,
  Divider,
  ExternalLink,
  Flex,
  FlexColumn,
  GridItem,
  Heading2,
  LayoutGridContainer,
} from 'components/DesignSystem/Library';

export interface DSP_UnauthenticatedPageLayoutProps {
  children?: React.ReactNode;
}

export const DSP_UnauthenticatedPageLayout = ({
  children,
}: DSP_UnauthenticatedPageLayoutProps) => {
  const year = new Date().getFullYear();
  const theme: Theme = useTheme();
  return (
    <LayoutGridContainer
      data-name={'login-page-grid-container'}
      height={{
        xs: 'auto',
        md: '100%',
      }}
      minHeight={{
        xs: 'auto',
        md: '100vh',
      }}
    >
      <GridItem
        data-name={'login-page-image-wrapper'}
        position={'relative'}
        display={'flex'}
        height={{
          xs: '256px',
          md: '100vh',
        }}
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={5}
      >
        <Box
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          sx={{
            backgroundColor: theme.palette.primary?.shades?.['50p'],
          }}
        />
        <img
          alt={'splash image'}
          src={splash}
          style={{
            objectFit: 'cover',
            objectPosition: 'center center',
          }}
          width={'100%'}
          height={'100%'}
        />
        <Box
          position={'absolute'}
          bottom={0}
          width={'100%'}
          textAlign={'center'}
          py={9}
        >
          {i18n.get('GLOBAL_BRAND_LOGO')}
        </Box>
      </GridItem>
      <GridItem
        data-name={'login-page-content-wrapper'}
        flexColumn
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={7}
        bgcolor={'background.default'}
        height={{
          md: '100vh',
        }}
        overflowY={{
          xs: 'visible',
          md: 'auto',
        }}
        alignItems={'space-between'}
        flexWrap={'nowrap'}
      >
        <Box data-name={'login-form-container'} flexGrow={1} p={2}>
          <FlexColumn
            data-name={'login-form-item-center'}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
            width={{
              xs: '100%',
              md: '416px',
            }}
            py={{xs: 4, sm: 4, md: 0, lg: 0, xl: 0}}
            mx={'auto'}
            gap={4}
          >
            <Heading2>{i18n.get('GLOBAL_APP_NAME_SHORT')}</Heading2>
            {children}
          </FlexColumn>
        </Box>
        <FlexColumn pt={2} pb={1}>
          <Divider />
          <Flex
            component={'footer'}
            flexDirection={{
              xs: 'column',
              sm: 'row',
            }}
            alignItems={'center'}
            justifyContent={'center'}
            gap={{
              xs: 2,
              sm: 4,
            }}
          >
            <ExternalLink href="https://www.nhvr.gov.au/law-policies/privacy">
              <Body1>Privacy</Body1>
            </ExternalLink>
            <ExternalLink href="https://www.nhvr.gov.au/disclaimer">
              <Body1>Disclaimer</Body1>
            </ExternalLink>
            <ExternalLink href="https://www.nhvr.gov.au/accessibility">
              <Body1>Accessibility</Body1>
            </ExternalLink>
            <ExternalLink href="https://www.nhvr.gov.au/contact-us">
              <Body1>Contact</Body1>
            </ExternalLink>
            <ExternalLink href="https://www.nhvr.gov.au/copyright">
              <Body1>{`© ${year} NHVR`}</Body1>
            </ExternalLink>
          </Flex>
        </FlexColumn>
      </GridItem>
    </LayoutGridContainer>
  );
};

DSP_UnauthenticatedPageLayout.displayName = 'DSP_UnauthenticatedPageLayout';

export {
  /** @deprecated use DSP_UnauthenticatedPageLayout instead*/
  DSP_UnauthenticatedPageLayout as UnauthenticatedPageLayout,
  //
  /** @deprecated use DSP_UnauthenticatedPageLayoutProps instead*/
  DSP_UnauthenticatedPageLayoutProps as UnauthenticatedPageLayoutProps,
};
