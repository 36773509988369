import AuditorRoutes from 'containers-accreditation/Auditors';
import AuditRequestsRoutes from 'containers-accreditation/AuditRequests';
import content from 'containers-accreditation/content';
import {AccreditationRoutes} from 'containers-accreditation/index';
import ManageAccreditationRoutes from 'containers-accreditation/Manage';

import {AssignmentOutlined} from '@mui/icons-material';

import Applications from './Applications';
import Auditors from './Auditors';
import AuditRequests from './AuditRequests';
import LandingSwitch from './LandingSwitch';

export default {
  content,
  routes: [
    LandingSwitch,
    Applications,
    Auditors,
    AuditRequests,
    ...ManageAccreditationRoutes,
    ...AccreditationRoutes,
    ...AuditorRoutes,
    ...AuditRequestsRoutes,
  ],
  menuTitle: 'National Heavy Vehicle Accreditation Scheme',
  menuDescription: 'National Heavy Vehicle Accreditation Scheme',
  path: '/accreditation-nhvas',
  showChildRoutesInMenu: true,
  menuIcon: AssignmentOutlined,
};
