import React from 'react';

import {Theme, darken, useTheme} from '@mui/material';

import {DSL_Box} from 'components/DesignSystem/Library';

import {SegmentAccessType} from '../../Layout/Segments/constants';

interface DSP_SegmentAccessIconProps {
  segmentAccessType?: SegmentAccessType;
  isSegmentUnmanaged?: boolean;
}

export const DSP_SegmentAccessIcon = ({
  segmentAccessType = SegmentAccessType.APPROVED_WITH_CONDITION,
  isSegmentUnmanaged = false,
}: DSP_SegmentAccessIconProps) => {
  const theme: Theme = useTheme();

  const colorKey =
    segmentAccessType === SegmentAccessType.REMOVED
      ? SegmentAccessType.NO_ACCESS
      : segmentAccessType === SegmentAccessType.NO_ACCESS
      ? isSegmentUnmanaged
        ? ('unmanagedRoads' as keyof typeof theme.mapBox)
        : SegmentAccessType.NO_ACCESS
      : segmentAccessType;

  const bgcolor = theme.mapBox[colorKey];
  const borderColor =
    colorKey === SegmentAccessType.NO_ACCESS
      ? darken(theme.mapBox[colorKey] as string, 0.4)
      : darken(theme.mapBox[colorKey] as string, 0.1);

  return (
    <DSL_Box
      data-name={`segment-access-icon-access-type-${segmentAccessType}`}
      internal_sx={{
        bgcolor,
        borderColor,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: theme.spacing(1),
        height: theme.spacing(5),
        width: theme.spacing(1.5),
      }}
    />
  );
};
