import React, {ForwardedRef, forwardRef} from 'react';
import {useAppBar} from './useAppBar';
import {AppBarView} from './AppBar.View';
import {AppBarUnAuthenticatedView} from './AppBarUnAuthenticated.View';

export interface AppBarProps {
  map?: boolean;
}

export const AppBar = forwardRef(
  ({map}: AppBarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {handleLogin, ...props} = useAppBar(map);

    const unAuthenticatedProps = {...props, map};

    // eslint-disable-next-line react/prop-types
    return props.userProfile ? (
      <AppBarView ref={ref} {...props} />
    ) : (
      <AppBarUnAuthenticatedView
        ref={ref}
        handleLogin={handleLogin}
        {...unAuthenticatedProps}
      />
    );
  },
);

AppBar.displayName = 'AppBar';
